import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link, graphql, StaticQuery } from "gatsby";
import Img from "gatsby-image";

class Categories extends Component {
  render() {
    const { data } = this.props;
    const { edges: categories } = data.allMarkdownRemark;

    return (
      <div className="container">
        <h2 className="mt-0">Our Products &amp; Services</h2>
        <div className="row">
          {categories &&
            categories.map(({ node: category }) => (
              <div className="col-md-4" key={category.id}>
                <Link to={category.fields.slug} className="card category-card">
                    <Img
                      alt={category.frontmatter.name}
                      className="card-img-top"
                      fluid={category.frontmatter.image.childImageSharp.fluid}
                    />
                  <div className="card-bar">
                    {category.frontmatter.name}
                  </div>
                </Link>
              </div>
            ))}
        </div>
      </div>
    );
  }
}

Categories.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
};

export default () => (
  <StaticQuery
    query={graphql`
      query categoriesQuery {
        allMarkdownRemark(
          filter: { frontmatter: { templateKey: { eq: "category" } } }
          sort: { fields: frontmatter___position }
        ) {
          edges {
            node {
              id
              fields {
                slug
              }
              frontmatter {
                image {
                  childImageSharp {
                    fluid(maxWidth: 650) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
                name
                templateKey
              }
            }
          }
        }
      }
    `}
    render={(data) => <Categories data={data} />}
  />
);
